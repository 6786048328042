<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;700&display=swap');
  
body {
    margin: 0;
    font-family: 'Barlow';
    /*font-family: 'Barlow', sans-serif;*/
    background-color: rgb(0, 0, 0);
  }
</style>
