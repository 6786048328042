<template>
    <div class="artwork-page">
      <div class="header">
        <span class="artwork-name">{{ artwork.artworkName }}</span>
        <span class="dots"></span>
        <span class="exhibition-name">{{ artwork.exhibitionName }}</span>
      </div>
      <div class="video-container" id="BF">
        <!--img :src="cdnUrl(artwork.image)" alt="Artwork Image" class="main-image" />
        <div class="text-block center-text fs14">
          <p>{{ artwork.description }}</p>
        </div-->
        <video autoplay muted loop playsinline class="video-clip">
            <source :src="cdnUrl('videos/Archive/03Video1.webm')" type="video/webm">
            Your browser does not support HTML5 video.
          </video>
      </div>

      <div class="text-block-t0 fs14 center-text mb-40">
        <p>
          [I] Aisthesis Savage's 3D-printed creations can be adapted to various setups, including MR head- sets.
        </p>
      </div>

      <div class="text-block left-text-100 mt-40 mb-40">
        <p>
            The "Human-like Non-human" series ventures into the realm of augmented electric perception, embedding wearable 3D-printed artificial organs to offer the audience a captivating journey through real-time audio-visual experiences.
        </p>
        <p>
          After consulting the engineering experts of Taiwan's Industrial Technology Research Institute, the artist has created a series of art installations and performances to serve as rituals that activate superhuman electrical sensing. Adorning the artist's creation, the Electro-second Organ, a biologically algorithmic marvel, functions as an interactive wearable, delivering 256-channel programmable de-voltage pulses for a unique art performance. Every single electronic array is developed in-house. Each participant is bestowed with electric stimulation patches, initiating their personal electric perception.
        </p>
      </div>

      <div class="carousel-container" id="carousel-container">
        <div class="carousel">
          <div class="carousel-inner" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
            <div v-for="(slide, index) in carouselSlides" :key="index" class="carousel-item">
              <img :src="cdnUrl(slide.image)" alt="carousel image" class="carousel-image">
            </div>
          </div>
        </div>
        <div class="carousel-caption">
          <div class="SlideDes">{{ carouselSlides[currentIndex].description }}</div>
          <div class="carousel-indicators">
            <div v-for="(slide, index) in carouselSlides" :key="index" :class="{ active: index === currentIndex }" @click="setCurrentIndex(index)"></div>
          </div>
        </div>
      </div>

      <div class="text-block left-text-100 mt-40 mb-40" id="AF">
        <p>
          The series, anchored in Unreal Engine and linked with custom Arduino-compatible portable stimulation patches, invites individuals to immerse themselves in three parts of well-orchestrated audiovisual and electrotactile performance: Cognitive Algorithms, Artificial Organs, and Electro-telepresence. This result unfolds as a ritualistic exploration of enhanced cognition, blending parameterized computation, real-time stable diffusion, and electro-tactility experiments. Welcome to "The Human-like Non-human Series: Cognitive Algorithms, Artificial Organs, and Electro-telepresence."
        </p>
        <p>
          From modern medicine to the development of bionic prosthetics using microelectronics, cyborg is no longer an imagination, but a widespread reality. The direct intrusion of electronic signals into the linkage of organisms is only the cyborg defined by the western point of view. If the eight senses of Eastern Buddhism correspond to the current field of human-computer interaction and human enhancement, how will the concept of cyborg evolve?
        </p>
      </div>

      <div class="content">

        <div class="SponContainer">
          <div class="title">Production Team</div>
            <div class="team-info">
                <div class="position">Director / LIVE
                </div>
                <div class="name">Lai Jiun Ting
                </div>
                <div class="position">Project Management
                </div>
                <div class="name">Chan Yueh Tung
                </div>
                <div class="position">Producer
                </div>
                <div class="name">Sebastian Tan
                </div>
                <div class="position">Technical Execution
                </div>
                <div class="name">Abner Cheng
                </div>
                <div class="position">Technical Assistant
                </div>
                <div class="name">Yi-Hsin Hsiao
                </div>
                <div class="position">Photography
                </div>
                <div class="name">Roger Lin
                </div>
            </div>
          </div>
        </div>

        <div class="SponContainer mb-40">
          <div class="title">2023</div>
        </div>

        <div class="SponContainer mb-20">
          <div class="title">In Association With and Sponsored By</div>
            <div class="team-info">
                <div class="w-100">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-11.png')" alt="Profile Picture" class="SponLogo">
                </div>
            </div>
          </div>

          <div class="SponContainer mb-40">
            <div class="title">CO-Organized By</div>
              <div class="team-info">
                <div class="w-100">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-16.png')" alt="Profile Picture" class="SponLogoS">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-17.png')" alt="Profile Picture" class="SponLogoS">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-18.png')" alt="Profile Picture" class="SponLogoS">
                </div>
              </div>
            </div>

          <div class="SponContainer mb-40">
            <div class="title">In Cooperation with</div>
              <div class="team-info">
                  <div class="w-100">
                    <img :src="cdnUrl('photos/Sponsors/Spo_logo-24.png')" alt="Profile Picture" class="SponLogo">
                  </div>
              </div>
            </div>

        </div>

          
    <WorkFooter />
  </template>
  
  <script>
    import WorkFooter from '../WorkFooter.vue';
  export default {
    components: {
      WorkFooter,
    },
    data() {
      return {
        currentIndex: 0,
        artwork: {
          artworkName: "[03] 《The Human-like Non-human Series - Data.Revoc_MR theater performance development plan》",
          exhibitionName: "S.0_0 00",
          image: "photos/Archive/[03]0.webp",
          description: `Model wearing HoloLens 2 headset, which was implemented at 'Data.Revoc - Human-like Non-human Superhuman MR Theater for participants to experience the mixed reality performance. The aesthetic in the background is part of the work showcased at Taipei Fine Arts Museum`,
          
        },
        carouselSlides: [
          { image: "photos/Archive/[03]1-1.webp", description: 'In 2023, Showcased at the MoonDream Reality MR Theater.' },
          { image: "photos/Archive/[03]1-4.webp", description: 'Audience experience' },
          { image: "photos/Archive/[03]1-3.webp", description: "3D-printed creations, developed as wearable devices." }
        ],
        touchStartX: 0,
        touchEndX: 0,
        scrollDelta: 0,
      };
    },
    methods: {
    setCurrentIndex(index) {
      this.currentIndex = index;
    },
    handleWheelEvent(event) {
      event.preventDefault(); // 阻止頁面滾動
      this.scrollDelta += event.deltaY; // 累積滾動量

      const threshold = 50; // 根據需要調整的閾值

      // 滾動量超過正向或負向閾值時觸發切換
      if (this.scrollDelta > threshold) {
        this.nextSlide();
        this.scrollDelta = 0; // 重置累積量
      } else if (this.scrollDelta < -threshold) {
        this.previousSlide();
        this.scrollDelta = 0; // 重置累積量
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.handleGesture();
    },
    handleGesture() {
      if (this.touchEndX < this.touchStartX) {
        this.nextSlide();
      } else if (this.touchEndX > this.touchStartX) {
        this.previousSlide();
      }
    },
    nextSlide() {
      if (this.currentIndex < this.carouselSlides.length - 1) {
        this.currentIndex++;
      } 
      else {
        //this.currentIndex = 0;
        this.scrollToSection('AF');
      }
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      else {
        //this.currentIndex = this.carouselSlides.length - 1;
        this.scrollToSection('BF');
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  mounted() {
    const carouselContainer = document.getElementById('carousel-container');
    if (carouselContainer) {
      carouselContainer.addEventListener('wheel', this.handleWheelEvent, { passive: false });
      carouselContainer.addEventListener('touchstart', this.handleTouchStart, { passive: true });
      carouselContainer.addEventListener('touchend', this.handleTouchEnd, { passive: true });
    }
  },
  beforeUnmount() {
    const carouselContainer = document.getElementById('carousel-container');
    if (carouselContainer) {
      carouselContainer.removeEventListener('wheel', this.handleWheelEvent);
      carouselContainer.removeEventListener('touchstart', this.handleTouchStart);
      carouselContainer.removeEventListener('touchend', this.handleTouchEnd);
    }
  }
};
</script>
  
  <style scoped>
  /*@import '@/assets/styles/archiveCSS.css';
*/
.artwork-page {
  color: #fff;
  background-color: #000;
  padding: 15px;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin-bottom: 20px;
}

.artwork-name, .exhibition-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 200;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.4;
}

.dots:before {
  content: "................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................";
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  opacity: 0.4;
}

.video-container {
  width: 100%;
  text-align: center;
}
.video-clip{
  width: 100%;
}

.main-image {
  width: 100%;
  height: auto;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 1%;
}

.text-block {
  background-color: #000000;
  padding: 20px;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin: auto;
}

.text-block-t0 {
  background-color: #000000;
  padding: 20px;
  padding-top: 5px !important;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

.left-text {
  width: 62%;
}

.right-text {
  width: 32%;
}

.center-text{
  width: 100%;
  text-align: center;
}

.center-text p{
  margin-top: 0px !important;
}

.left-text-100 {
  width: 100%;
}

.main-text {
  width: 94%;
  background-color: #000;
  padding: 20px;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}

.carousel {
  width: 100%;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-inner {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background: rgb(55, 55, 55, 0.8);
  color: white;
  padding: 20px 30px;
  text-align: left;
}

.carousel-caption .caption-footer {
  margin-top: 12px;
  font-size: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel-indicators {
  display: flex;
  align-items: center;
}

.carousel-indicators div {
  width: 3px;
  height: 10px;
  background: gray;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 5px;
}

.carousel-indicators div.active {
  background: white;
  height: 20px;
}

.mt-20{
  margin-top: 20px;
}

.mt-40{
  margin-top: 40px;
}

.mb-20{
  margin-bottom: 20px;
}

.mb-40{
  margin-bottom: 40px;
}

.mb-100{
  margin-bottom: 100px;
}

.fs14{
  font-size: 14px;
  color: #A3A3A3;
}

.SlideDes{
  font-size: 14px;
  margin-bottom: 6px;
}

/*額外*/ 
.SponContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.w-100{
  width: 100%;
  display: flex;
}
.title {
    width: 50%;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    color: #d0d1d1;
    justify-content: flex-start; /* 改為左對齊 */
}
.team-info {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    line-height: 24px;
}
.position, .name {
    width: 50%;
    box-sizing: border-box;
}
.position {
    color: #b5b6b6;
    text-align: left; /* 左對齊 */
}
.name {
    text-align: right; /* 右對齊 */
    color: #ffffff;
}
.SponLogo{
  height: 40px;
  margin-bottom: 24px;
}
.SponLogoS{
  height: 55px;
  margin-right: 24px;
  margin-bottom: 24px;
}
.Sponimage{
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 576px) {
  .left-text {
    width: 100% !important;
  }
  
  .right-text {
    width: 100% !important;
  }

  .content {
  flex-direction: column;
  }
  .artwork-name, .exhibition-name {
    font-size: 16px;
  }
  .fs14{
    font-size: 10px;
  }
  .left-text-100 {
    font-size: 14px;
    line-height: 18px;
  }

  .title {
      width: 100%;
  }
  .team-info {
      width: 100%;
  }

  .Sponimage{
    width: 100%;
    height: auto;
    display: block;
  }
  .SlideDes{
    font-size: 12px;
  }
}

  </style>
  