<template>
    <div class="artwork-page">
      <div class="header">
        <span class="artwork-name">{{ artwork.artworkName }}</span>
        <span class="dots"></span>
        <span class="exhibition-name">{{ artwork.exhibitionName }}</span>
      </div>

      <div class="video-container">
        <img :src="cdnUrl(artwork.image)" alt="Artwork Image" class="main-image" />
        <!--div class="text-block center-text fs14">
          <p>{{ artwork.description }}</p>
        </div-->
        <!--video autoplay muted loop playsinline class="video-clip">
          <source :src="cdnUrl('videos/Archive1.webm')" type="video/webm">
          Your browser does not support HTML5 video.
        </video-->
      </div>

      <div class="headerH">
        <p class="text-block artwork-name">{{ artwork.artworkName }}</p>
      </div>
      

      <!--div class="TopTitle">
        <p class="artwork-name">{{ artwork.artworkName }}</p>
        <p class="exhibition-name">{{ artwork.exhibitionName }}</p>
      </div-->

      <!--div class="text-block-t0 center-text fs14">
        <p>
          [I] The project "Electro-tactile Liberation"and the final version of the Human-like Non-human series.
        </p>
      </div-->

      <div class="content">
        <div class="text-block left-text-100">
          <p class="large-text">
            "Must humanity remain human?" 
          </p>
          <p>
            The Human-like Non-human Series emerges at the intersection of "cognitive algorithms of surveillance capitalism" and "cybernetic organisms," exploring how the nature of "I" is shaped by interactions between the brain's intrinsic natural algorithms and artificial algorithms. Through the "Second Organ of Electro-tactility," an interactive wearable device, Lai Jiun-Ting liberates human perceptual capacity using subtle electric currents. This demonstrates how technology, as an artificial organ, can enhance human cognition by grasping the limits of information while expanding, shaping, and even manipulating the boundaries of human biological identity. Engaging in a realm of hybrid artistic experimentation, an essential question arises: "Must a human be human, yet also resemble something non-human?"
          </p>
          <p>
            
          </p>
        </div>
      </div>

      <div class="video-container mt-20">
          <video autoplay muted loop playsinline class="video-clip">
            <source :src="cdnUrl('videos/Archive/01video01new.webm')" type="video/webm">
            Your browser does not support HTML5 video.
          </video>
      </div>

      <div class="text-block left-text-100 mt-40 mb-40">
        <p>
          The series of artworks delves into the realm of reactive implantable technology to manipulate the audience, and draws inspiration from Eastern philosophical notions emphasizing cognitive expansion, thus contributing to the “Transhumanism Movement.” 
        </p>
      </div>

      <div class="video-container mt-20">
          <video autoplay muted loop playsinline class="video-clip">
            <source :src="cdnUrl('videos/Archive/01Video2.webm')" type="video/webm">
            Your browser does not support HTML5 video.
          </video>
      </div>

      <!--div class="text-block-t0 center-text fs14 mb-40" id="BF">
        <p>
          [II] The project "Electro-tactile Liberation"and the final version of the Human-like Non-human series.
        </p>
      </div-->

      <div class="video-container mt-20">
          <video autoplay muted loop playsinline class="video-clip">
            <source :src="cdnUrl('videos/Archive/01Video3.webm')" type="video/webm">
            Your browser does not support HTML5 video.
          </video>
      </div>

      <div class="text-block-t0 center-text fs14">
        <p>
          Aisthesis Savage has further refined the integration of circuit boards and 3D printing.
        </p>
      </div>

      <div class="text-block left-text-100 mt-40 mb-40">
        <p>
          Offering a critical alternative to Western cyborg perspectives rooted in taxonomy and microelectronics politics, this series also grapples with the contradiction and illusion of the human brain as a predicting machine, providing new insights into the intricate relationship between "human," artificial evolution, and algorithmic technology.
        </p>
      </div>

      <div class="carousel-container" id="carousel-container">
        <div class="carousel">
          <div class="carousel-inner" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
            <div v-for="(slide, index) in carouselSlides" :key="index" class="carousel-item">
              <img :src="cdnUrl(slide.image)" alt="carousel image" class="carousel-image">
            </div>
          </div>
        </div>
        <div class="carousel-caption">
          <div class="caption-content">
            <div class="carousel-indicators">
              <div v-for="(slide, index) in carouselSlides" :key="index" :class="{ active: index === currentIndex }" @click="setCurrentIndex(index)"></div>
            </div>
            <div class="SlideDes">
              {{ carouselSlides[currentIndex].description }}
            </div>
          </div>
          <!--div class="caption-content">
            <div class="carousel-indicators">
              <div v-for="(slide, index) in carouselSlides" :key="index" :class="{ active: index === currentIndex }" @click="setCurrentIndex(index)"></div>
            </div>
            <div class="SlideDes">{{ carouselSlides[currentIndex].description }}</div>
          </div-->
        </div>
      </div>

      <div class="text-block left-text-100 mt-40 mb-40" id="AF">
        <p>
          In 2023 "Human-like Non-human Series" performance system has been remade and presented within a game engine, featuring wearable devices and providing a more refined electro-tactility experience.
        </p>
      </div>

      <div class="video-container mt-20">
          <video controls class="video-clip">
            <source :src="cdnUrl('videos/Archive/01Video4.webm')" type="video/webm">
            Your browser does not support HTML5 video.
          </video>
      </div>


      <div class="text-block-t0 center-text mb-40 fs14">
        <p>
          The exhibition at the Taipei Art Awards included regular performances and four live improv composition performances. 
        </p>
      </div>

      <!--div class="main-text">
        <p>Lai Jiun-Ting is a new media artist born and raised in Taipei. His practice focuses on the relationship between technology rights and individuals, and is currently working on the subject of "cognitive enhancement based on human-AI integration" through experimenting wearable and intricately integrated devices that enhance human cognition as an intermediary means to resist "implantable surveillance capitalism." He utilizes electroreception of the human tongue as a means to create perceptual sculpture and experiments with the potentiality of human cognitive enhancement on the body part in an invasive yet non-implanted fashion. At the moment, he continues with his journey of exploring electro-tactility as a form of art in Taipei</p>
      </div-->

      <div class="content">

        <div class="SponContainer">
          <div class="title">Production Team</div>
            <div class="team-info">
                <div class="position">Director / LIVE
                </div>
                <div class="name">Lai Jiun Ting
                </div>
                <div class="position">Project Management
                </div>
                <div class="name">Chan Yueh Tung
                </div>
                <div class="position">Technical Execution
                </div>
                <div class="name">Abner Cheng
                </div>
                <div class="position">Associate Producer
                </div>
                <div class="name">Sebastian Tan
                </div>
                <div class="position">Photography
                </div>
                <div class="name">Roger Lin
                </div>
                <div class="position">Model
                </div>
                <div class="name">HoHo
                </div>
            </div>
          </div>
        </div>

        <div class="SponContainer mb-40">
          <div class="title">2023</div>
        </div>

        <div class="SponContainer">
          <div class="title">In Association With and Sponsored By</div>
            <div class="team-info">
                <div class="w-100">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-11.png')" alt="Profile Picture" class="SponLogo">
                </div>
                <div class="w-100">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-12.png')" alt="Profile Picture" class="SponLogo">
                </div>
                <div class="w-100">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-13.png')" alt="Profile Picture" class="SponLogoS">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-14.png')" alt="Profile Picture" class="SponLogoS">
                </div>
            </div>
          </div>

          <div class="SponContainer mb-40">
            <div class="title">Organizer</div>
              <div class="team-info">
                  <div class="w-100">
                    <img :src="cdnUrl('photos/Sponsors/Spo_logo-15.png')" alt="Profile Picture" class="SponLogo">
                  </div>
              </div>
            </div>
            
        </div>

          

    <WorkFooter />

  </template>
  
  <script>
   import WorkFooter from '../WorkFooter.vue';

    export default {
      components: {
        WorkFooter,
      },
      data() {
      return {
        currentIndex: 0,
        artwork: {
          artworkName: "[01]《The Human-like Non-human Series: Cognitive Algorithms, Artificial Organs and Elector-telepresence》",
          exhibitionName: "S.O_O 01",
          image: "photos/Archive/[01]0.webp",
          description: `[I] The project "Electro-tactile Liberation"and the final version of the Human-like Non-human series."`,
          
        },
        carouselSlides: [
          { image: "photos/Archive/[01]1-1.webp", description: 'Presented At the Taipei Fine Art Awards' },
          { image: "photos/Archive/[01]1-2.webp", description: 'Presented At the Taipei Fine Art Awards' },
          { image: "photos/Archive/[01]1-3.webp", description: 'Presented At the Taipei Fine Art Awards' },
          { image: "photos/Archive/[01]1-4.webp", description: 'Presented At the Taipei Fine Art Awards' }
        ],
        touchStartX: 0,
        touchEndX: 0,
        scrollDelta: 0,
      };
    },
    methods: {
    setCurrentIndex(index) {
      this.currentIndex = index;
    },
    handleWheelEvent(event) {
      event.preventDefault(); // 阻止頁面滾動
      this.scrollDelta += event.deltaY; // 累積滾動量

      const threshold = 50; // 根據需要調整的閾值

      // 滾動量超過正向或負向閾值時觸發切換
      if (this.scrollDelta > threshold) {
        this.nextSlide();
        this.scrollDelta = 0; // 重置累積量
      } else if (this.scrollDelta < -threshold) {
        this.previousSlide();
        this.scrollDelta = 0; // 重置累積量
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.handleGesture();
    },
    handleGesture() {
      if (this.touchEndX < this.touchStartX) {
        this.nextSlide();
      } else if (this.touchEndX > this.touchStartX) {
        this.previousSlide();
      }
    },
    nextSlide() {
      if (this.currentIndex < this.carouselSlides.length - 1) {
        this.currentIndex++;
      } 
      else {
        //this.currentIndex = 0;
        this.scrollToSection('AF');
      }
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      else {
        //this.currentIndex = this.carouselSlides.length - 1;
        this.scrollToSection('BF');
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  mounted() {
    const carouselContainer = document.getElementById('carousel-container');
    if (carouselContainer) {
      carouselContainer.addEventListener('wheel', this.handleWheelEvent, { passive: false });
      carouselContainer.addEventListener('touchstart', this.handleTouchStart, { passive: true });
      carouselContainer.addEventListener('touchend', this.handleTouchEnd, { passive: true });
    }
  },
  beforeUnmount() {
    const carouselContainer = document.getElementById('carousel-container');
    if (carouselContainer) {
      carouselContainer.removeEventListener('wheel', this.handleWheelEvent);
      carouselContainer.removeEventListener('touchstart', this.handleTouchStart);
      carouselContainer.removeEventListener('touchend', this.handleTouchEnd);
    }
  }
};
</script>
  
<style scoped>

.artwork-page {
  color: #fff;
  background-color: #000;
  /*padding: 15px;*/
}

.header {
  display: none;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.TopTitle {
  position: absolute;
  top: 12px;
}

.TopTitle p{
  margin: 12px 0px;
}

.artwork-name, .exhibition-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 200;
}

.exhibition-name {
  color: #b2b4b5;
}
.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.4;
}

.dots:before {
  content: "................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................";
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  opacity: 0.4;
}

.video-container {
  width: 100%;
  text-align: center;
}
.video-clip{
  width: 100%;
}

.main-image {
  width: 100%;
  height: auto;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 1%;
}

.text-block {
  background-color: #000000;
  padding: 36px;
  box-sizing: border-box;
  line-height: 14px;
  letter-spacing: 0.5px;
  margin: auto;
}

.text-block-t0 {
  background-color: #000000;
  padding: 36px;
  padding-top: 5px !important;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

.left-text {
  width: 62%;
}

.right-text {
  width: 32%;
}

.center-text{
  width: 100%;
  text-align: center;
}

.center-text p{
  margin-top: 0px !important;
}

.left-text-100 {
  width: 100%;
}

.main-text {
  width: 94%;
  background-color: #000;
  padding: 20px;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.large-text{
  font-size: 24px;
  margin-bottom: 48px;
}

.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}

.carousel {
  width: 100%;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-inner {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background: rgb(55, 55, 55, 0.8);
  color: white;
  text-align: left;
}

.carousel-caption .caption-footer {
  margin-top: 12px;
  font-size: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.caption-content {
  display: flex;          
  justify-content: flex-start; 
  align-items: center;  
  width: 100%; 
}

.carousel-indicators {
  display: flex; 
  align-items: center;
  gap: 5px; 
  padding-left: 6px; 
}

.carousel-indicators div {
  width: 3px;
  height: 10px;
  background: gray;
  cursor: pointer;
  transition: all 0.3s;
}

.carousel-indicators div.active {
  background: white;
  height: 20px;
}

.SlideDes {
  padding-left: 8px;
  flex-grow: 1; 
  text-align: left;
  font-size: 14px;
  margin-bottom: 6px; 
}

  .headerH{
      display: block;
    }

  .headerH p{
    padding-top: 8px;
    padding-bottom: 0px;
  }

/*
.caption-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
}

.carousel-indicators {
  padding-left: 6px;
  width: 400px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.carousel-indicators div {
  width: 3px;
  height: 10px;
  background: gray;
  cursor: pointer;
  transition: all 0.3s;
}

.carousel-indicators div.active {
  background: white;
  height: 20px;
}

.SlideDes{
  flex-grow: 1; 
  text-align: left;
  font-size: 14px;
  margin-bottom: 6px;
}*/

.mt-20{
  margin-top: 20px;
}

.mt-40{
  margin-top: 40px;
}

.mb-20{
  margin-bottom: 20px;
}

.mb-40{
  margin-bottom: 40px;
}

.mb-100{
  margin-bottom: 100px;
}

.fs14{
  font-size: 14px;
  color: #A3A3A3;
}


/*額外*/ 
.SponContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.w-100{
  width: 100%;
  display: flex;
}
.title {
    width: 50%;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    color: #d0d1d1;
    justify-content: flex-start; 
}
.team-info {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    line-height: 24px;
}
.position, .name {
    width: 50%;
    box-sizing: border-box;
}
.position {
    color: #b5b6b6;
    text-align: left;
}
.name {
    text-align: right; 
    color: #ffffff;
}
.SponLogo{
  height: 40px;
  margin-bottom: 24px;
}
.SponLogoS{
  height: 55px;
  margin-right: 24px;
  margin-bottom: 24px;
}
.Sponimage{
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 576px) {

  .TopTitle {
    display: none;
  }
  .header {
    display: grid;
  }
  .left-text {
    width: 100% !important;
  }
  
  .right-text {
    width: 100% !important;
  }

  .content {
  flex-direction: column;
  }
  .artwork-name, .exhibition-name {
    font-size: 16px;
  }
  .fs14{
    font-size: 10px;
  }
  .left-text-100 {
    font-size: 14px;
    line-height: 18px;
  }

  .title {
      width: 100%;
  }
  .team-info {
      width: 100%;
  }

  .Sponimage{
    width: 100%;
    height: auto;
    display: block;
  }
  .SlideDes{
    font-size: 12px;
  }
  .headerH{
    display: none;
  }
  .large-text{
    font-size: 18px;
    margin-bottom: 36px;
  }
}
  </style>
  