export default {
    data() {
      return {
        cdnPrefix: 'https://aisthesis.sgp1.cdn.digitaloceanspaces.com'
      };
    },
    methods: {
      cdnUrl(path) {
        return `${this.cdnPrefix}/${path}`;
      }
    }
  };