import { createRouter, createWebHistory } from 'vue-router';
import ArchiveNo01 from './components/artworks/ArchiveNo01.vue';
import ArchiveNo02 from './components/artworks/ArchiveNo02.vue';
import ArchiveNo03 from './components/artworks/ArchiveNo03.vue';
import ArchiveNo04 from './components/artworks/ArchiveNo04.vue';
import ArchiveNo05 from './components/artworks/ArchiveNo05.vue';
import ArchiveNo06 from './components/artworks/ArchiveNo06.vue';
import ArchiveNo07 from './components/artworks/ArchiveNo07.vue';
import ArchiveNo08 from './components/artworks/ArchiveNo08.vue';
import ArchiveNo09 from './components/artworks/ArchiveNo09.vue';
import ArchiveNo10 from './components/artworks/ArchiveNo10.vue';
// 引入其他作品頁面組件

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./components/HomePage.vue')
  },
  {
    path: '/contact',
    name: 'ContactSolo',
    component: () => import('./components/ContactSolo.vue')
  },
  {
    path: '/artwork/ArchiveNo01',
    name: 'ArchiveNo01',
    component: ArchiveNo01
  },
  {
    path: '/artwork/ArchiveNo02',
    name: 'ArchiveNo02',
    component: ArchiveNo02
  },
  {
    path: '/artwork/ArchiveNo03',
    name: 'ArchiveNo03',
    component: ArchiveNo03
  },
  {
    path: '/artwork/ArchiveNo04',
    name: 'ArchiveNo04',
    component: ArchiveNo04
  },
  {
    path: '/artwork/ArchiveNo05',
    name: 'ArchiveNo05',
    component: ArchiveNo05
  },
  {
    path: '/artwork/ArchiveNo06',
    name: 'ArchiveNo06',
    component: ArchiveNo06
  },
  {
    path: '/artwork/ArchiveNo07',
    name: 'ArchiveNo07',
    component: ArchiveNo07
  },
  {
    path: '/artwork/ArchiveNo08',
    name: 'ArchiveNo08',
    component: ArchiveNo08
  },
  {
    path: '/artwork/ArchiveNo09',
    name: 'ArchiveNo09',
    component: ArchiveNo09
  },
  {
    path: '/artwork/ArchiveNo10',
    name: 'ArchiveNo10',
    component: ArchiveNo10
  },
  // 添加其他作品頁面路由
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// 添加全局導航守衛來追蹤頁面來源
router.beforeEach((to, from, next) => {
  // 檢查是否是從一個作品詳細頁跳轉的
  if (from.name && from.name.startsWith('ArchiveNo')) {
    // 保存上一頁的名稱到 sessionStorage
    sessionStorage.setItem('lastVisitedPage', from.name);
  }
  next(); // 繼續導航
});

export default router;
