<template>
    <div class="artwork-page">
      <div class="header">
        <span class="artwork-name">{{ artwork.artworkName }}</span>
        <span class="dots"></span>
        <span class="exhibition-name">{{ artwork.exhibitionName }}</span>
      </div>
      <div class="video-container">
        <img :src="cdnUrl(artwork.image)" alt="Artwork Image" class="main-image" />
        <img :src="cdnUrl(artwork.image2)" alt="Artwork Image" class="main-image" />
        <div class="text-block center-text fs14">
          <p>{{ artwork.description }}</p>
        </div>
        <!--video autoplay muted loop playsinline class="video-clip">
          <source :src="cdnUrl('videos/Archive1.webm')" type="video/webm">
          Your browser does not support HTML5 video.
        </video-->
      </div>
      <div class="content" id="BF">
        <div class="text-block left-text-100">
          <p>
            Electro-Ashura: A journey of cultivating and unleashing dibba-sota: the 'divine ear', falling into the Shurado of electric pleasure.
          </p>
          <p>
            Electro Asura: dibba-sota: the 'divine ear', is an Audio-Visual + electrotactile sculpture. Participants will wear a portable interactive device that activates electrotactile cognition. The human-machine integration will obtain a hearing ability that surpasses that of humans, perceiving an artistic experience on the tongue that Homo sapiens can never have. The work is composed of 4 chapters and is estimated to be 14 minutes long. 
          </p>
          <p>
            The music is composed from Infrasound below 20Hz to Ultrasound that goes beyond 200KHz. It composes a movement with data beyond the range that the human ear can ever perceive,putting electro-tactility into effect with this very piece of work, "Dibba-Sota". As it creates a whole new way for people to experience senses, it becomes a music movement composed of electro-tactile noise that surpasses the limits of human perception, practising non-implanted oriental superhuman art.
          </p>
        </div>
      </div>

      <div class="carousel-container" id="carousel-container">
        <div class="carousel">
          <div class="carousel-inner" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
            <div v-for="(slide, index) in carouselSlides" :key="index" class="carousel-item">
              <img :src="cdnUrl(slide.image)" alt="carousel image" class="carousel-image">
            </div>
          </div>
        </div>
        <div class="carousel-caption">
          <div class="SlideDes">{{ carouselSlides[currentIndex].description }}</div>
          <div class="carousel-indicators">
            <div v-for="(slide, index) in carouselSlides" :key="index" :class="{ active: index === currentIndex }" @click="setCurrentIndex(index)"></div>
          </div>
        </div>
      </div>

      <div class="text-block left-text-100 mt-40 mb-100" id="AF">
        <p>
          Electro-tactility—the perception we’ve been developing for people to perceive natural electrical stimuli—emerges as a symbol of Eastern cognition enhancement. It embodies the temptation that hinders one from transcending sensory experience, ultimately transforming the seeker into an "electro Asura," a being who, ensnared by the allure of enhanced perception, cannot attain Buddhahood. This concept manifests the fallen aesthetics of electro-tactility as a stimulus, a poignant exploration of the tension between sensory augmentation and spiritual liberation.
        </p>
        <p>
          
        </p>
        <p>
          "The heart is born to evolve, vowing to dance wildly until that flower withers into barren branches."
        </p>
      </div>


      <div class="content">

        <div class="SponContainer">
          <div class="title">Production Team</div>
            <div class="team-info">
                <div class="position">Airtist
                </div>
                <div class="name">Lai Jiun Ting
                </div>
                <div class="position">Project Management
                </div>
                <div class="name">Chan Yueh Tung
                </div>
                <div class="position">Productor
                </div>
                <div class="name">Sebastian Tan
                </div>
                <div class="position">Photography
                </div>
                <div class="name">Roger Lin
                </div>
            </div>
          </div>
        </div>

        <div class="SponContainer mb-40">
          <div class="title">2022</div>
        </div>

        <div class="SponContainer mb-40">
          <div class="title">In Association With and Sponsored By</div>
            <div class="team-info">
                <div class="w-100">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-11.png')" alt="Profile Picture" class="SponLogo">
                </div>
                <div class="w-100">
                  <img :src="cdnUrl('photos/Sponsors/Spo_logo-19.png')" alt="Profile Picture" class="SponLogo">
                </div>
            </div>
          </div>
        </div>


    
    <WorkFooter />
  </template>
  
  <script>
  import WorkFooter from '../WorkFooter.vue';
  export default {
    components: {
      WorkFooter,
    },
    data() {
      return {
        currentIndex: 0,
        artwork: {
          artworkName: "[04]《 ElectroAsura_Dibba-Sota Prototype》",
          exhibitionName: "Dibba-Sota",
          image: "photos/Archive/[04]0-0.webp",
          image2: "photos/Archive/[04]0-2.webp",
          description: `[I] Electro Asura - "Dibba-Sota" the Divine Ear: Concept and Interaction Design`,
          
        },
        carouselSlides: [
          { image: "photos/Archive/[04]1-1.webp", description: 'Electro Asura - "Dibba-Sota" (the Divine Ear)' },
          { image: "photos/Archive/[04]1-2.webp", description: 'The spine of an Electro Asura.' }
        ],
        touchStartX: 0,
        touchEndX: 0,
        scrollDelta: 0,
      };
    },
    methods: {
    setCurrentIndex(index) {
      this.currentIndex = index;
    },
    handleWheelEvent(event) {
      event.preventDefault(); // 阻止頁面滾動
      this.scrollDelta += event.deltaY; // 累積滾動量

      const threshold = 50; // 根據需要調整的閾值

      // 滾動量超過正向或負向閾值時觸發切換
      if (this.scrollDelta > threshold) {
        this.nextSlide();
        this.scrollDelta = 0; // 重置累積量
      } else if (this.scrollDelta < -threshold) {
        this.previousSlide();
        this.scrollDelta = 0; // 重置累積量
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.handleGesture();
    },
    handleGesture() {
      if (this.touchEndX < this.touchStartX) {
        this.nextSlide();
      } else if (this.touchEndX > this.touchStartX) {
        this.previousSlide();
      }
    },
    nextSlide() {
      if (this.currentIndex < this.carouselSlides.length - 1) {
        this.currentIndex++;
      } 
      else {
        //this.currentIndex = 0;
        this.scrollToSection('AF');
      }
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      else {
        //this.currentIndex = this.carouselSlides.length - 1;
        this.scrollToSection('BF');
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  mounted() {
    const carouselContainer = document.getElementById('carousel-container');
    if (carouselContainer) {
      carouselContainer.addEventListener('wheel', this.handleWheelEvent, { passive: false });
      carouselContainer.addEventListener('touchstart', this.handleTouchStart, { passive: true });
      carouselContainer.addEventListener('touchend', this.handleTouchEnd, { passive: true });
    }
  },
  beforeUnmount() {
    const carouselContainer = document.getElementById('carousel-container');
    if (carouselContainer) {
      carouselContainer.removeEventListener('wheel', this.handleWheelEvent);
      carouselContainer.removeEventListener('touchstart', this.handleTouchStart);
      carouselContainer.removeEventListener('touchend', this.handleTouchEnd);
    }
  }
};
</script>
  
  <style scoped>
    /*@import '@/assets/styles/archiveCSS.css';
*/
.artwork-page {
  color: #fff;
  background-color: #000;
  padding: 15px;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin-bottom: 20px;
}

.artwork-name, .exhibition-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 200;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.4;
}

.dots:before {
  content: "................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................";
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  opacity: 0.4;
}

.video-container {
  width: 100%;
  text-align: center;
}
.video-clip{
  width: 100%;
}

.main-image {
  width: 100%;
  height: auto;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 1%;
}

.text-block {
  background-color: #000000;
  padding: 20px;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin: auto;
}

.text-block-t0 {
  background-color: #000000;
  padding: 20px;
  padding-top: 5px !important;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

.left-text {
  width: 62%;
}

.right-text {
  width: 32%;
}

.center-text{
  width: 100%;
  text-align: center;
}

.center-text p{
  margin-top: 0px !important;
}

.left-text-100 {
  width: 100%;
}

.main-text {
  width: 94%;
  background-color: #000;
  padding: 20px;
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}

.carousel {
  width: 100%;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-inner {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background: rgb(55, 55, 55, 0.8);
  color: white;
  padding: 20px 30px;
  text-align: left;
}

.carousel-caption .caption-footer {
  margin-top: 12px;
  font-size: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel-indicators {
  display: flex;
  align-items: center;
}

.carousel-indicators div {
  width: 3px;
  height: 10px;
  background: gray;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 5px;
}

.carousel-indicators div.active {
  background: white;
  height: 20px;
}

.mt-20{
  margin-top: 20px;
}

.mt-40{
  margin-top: 40px;
}

.mb-20{
  margin-bottom: 20px;
}

.mb-40{
  margin-bottom: 40px;
}

.mb-100{
  margin-bottom: 100px;
}

.fs14{
  font-size: 14px;
  color: #A3A3A3;
}

.SlideDes{
  font-size: 14px;
  margin-bottom: 6px;
}

/*額外*/ 
.SponContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.w-100{
  width: 100%;
  display: flex;
}
.title {
    width: 50%;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    color: #d0d1d1;
    justify-content: flex-start; /* 改為左對齊 */
}
.team-info {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    line-height: 24px;
}
.position, .name {
    width: 50%;
    box-sizing: border-box;
}
.position {
    color: #b5b6b6;
    text-align: left; /* 左對齊 */
}
.name {
    text-align: right; /* 右對齊 */
    color: #ffffff;
}
.SponLogo{
  height: 40px;
  margin-bottom: 24px;
}
.SponLogoS{
  height: 55px;
  margin-right: 24px;
  margin-bottom: 24px;
}
.Sponimage{
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 576px) {
  .left-text {
    width: 100% !important;
  }
  
  .right-text {
    width: 100% !important;
  }

  .content {
  flex-direction: column;
  }
  .artwork-name, .exhibition-name {
    font-size: 16px;
  }
  .fs14{
    font-size: 10px;
  }
  .left-text-100 {
    font-size: 14px;
    line-height: 18px;
  }

  .title {
      width: 100%;
  }
  .team-info {
      width: 100%;
  }

  .Sponimage{
    width: 100%;
    height: auto;
    display: block;
  }
  .SlideDes{
    font-size: 12px;
  }
}

  </style>
  